import React, { memo } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { TextInput } from '../../Atoms/Inputs/Text'
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel'
import { ruleConditionOptions } from '../../../utils/selectOptions'

export const ResultRow = memo(
    ({ parameters, result, updateResultById, removeResultById }) => {
        const updateResult = (key, value) => {
            updateResultById({
                ...result,
                [key]: value,
            })
        }
        const removeResult = (id) => {
            removeResultById(id)
        }
        return (
            <div
                className="formResult"
                style={{
                    border: '1px solid #blue',
                }}
            >
                <div className="formItem">
                    <SelectWithLabelInput
                        options={parameters}
                        label="Parameter"
                        defaultValue={result.parameter}
                        onChange={({ target }) => {
                            updateResult('parameter', target.value)
                        }}
                    />
                    {/*<SelectWithLabelInput*/}
                    {/*    options={ruleConditionOptions}*/}
                    {/*    label="Condition"*/}
                    {/*    defaultValue={result.condition}*/}
                    {/*    onChange={({ target }) => {*/}
                    {/*        updateResult('condition', target.value)*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <TextInput
                        placeholder="Value"
                        label="Value"
                        value={result.value}
                        onChange={({ target }) => {
                            updateResult('value', target.value)
                        }}
                    />
                    <div
                        className="remove"
                        onClick={() => removeResult(result.id)}
                    >
                        -
                    </div>
                </div>
            </div>
        )
    },
)
