import { Api } from '../../Services/Api'
import { Users } from '../../Services/users'

function normalizeRule(response, dataModelsForNormalize) {
    if (response?.rules?.length) {
        const data = response.rules.map((rule) => {
            return {
                ...rule.models[0],
                user: Users().getUserById(rule.models?.[0].userId)?.name,
                dataModel: dataModelsForNormalize.find(
                    (dm) => dm._id === rule.modelId,
                )?.name,
                _id: rule._id,
            }
        })
        return data.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
        )
    } else {
        return []
    }
}

export function getAllRules(dataModelsForNormalize) {
    const page = 1
    const limit = 10000
    return Api()
        .get(`api/rules/all?page=${page}&limit=${limit}`)
        .then((response) => {
            const data = normalizeRule(response, dataModelsForNormalize)
            return data
        })
}

export function getRuleById({ id }) {
    return Api().get(`api/rules/${id}`)
}

export function createRule(entity, dataModelsForNormalize) {
    return Api()
        .post(`api/rules/create`, entity)
        .then((response) => normalizeRule(response, dataModelsForNormalize))
}

export function ruleUpdate({
    name,
    ruleId,
    modelId,
    type,
    groups,
    conditions,
    outParameters,
    results,
}) {
    return Api().put(`api/rules/update/${ruleId}`, {
        name,
        ruleId,
        modelId,
        type,
        groups,
        conditions,
        outParameters,
        results,
    })
}

export function archiveRuleById(id) {
    return Api().patch(`api/rules/archive/${id}`)
}

export function getRulesByDataModelId(
    { page = 0, limit = 1000, modelId },
    dataModelsForNormalize,
) {
    return Api()
        .get(`api/rules/data-model/${modelId}?page=${page}&limit=${limit}`)
        .then((response) => {
            const data = normalizeRule(response, dataModelsForNormalize)

            return data
        })
}
